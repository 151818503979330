import './app.css';
import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import {CommonProvider} from '@common/core/common-provider';
import * as Sentry from '@sentry/react';
import {rootEl} from '@ui/root-el';
import {getBootstrapData} from '@ui/bootstrap-data/bootstrap-data-store';
import {ignoredSentryErrors} from '@common/errors/ignored-sentry-errors';
import {appRouter} from '@app/app-router';
import {PurchaseCode} from '@app/agent/purchase-code';
import type {AfterReplyAction} from '@app/agent/agent-ticket-page/use-after-reply-action';
import {initSearchTermLogger} from '@helpdesk/help-center/search/use-search-term-logger';
import {FetchCustomPageResponse} from '@common/custom-page/use-custom-page';
import {Tag} from '@common/tags/tag';
import {ImapConnectionCredentials} from '@app/admin/settings/incoming-email/imap-connection-credentials';
import {BaseBackendSettings} from '@common/core/settings/base-backend-settings';
import {BaseBackendUser} from '@common/auth/base-backend-user';
import {BaseBackendBootstrapData} from '@common/core/base-backend-bootstrap-data';
import {CustomerNewTicketPageConfig} from '@app/hc/tickets/customer-new-ticket-page/customer-new-ticket-page-config';
import {HcLandingPageConfig} from '@helpdesk/help-center/appearance/hc-landing-page-config';
import {HcBootstrapLoaders} from '@helpdesk/help-center/hc-bootstrap-loaders';

declare module '@common/http/value-lists' {
  interface FetchValueListsResponse {
    //
  }
}

declare module '@ui/bootstrap-data/bootstrap-data' {
  interface BootstrapData extends BaseBackendBootstrapData {
    loaders?: HcBootstrapLoaders & {
      customPage?: FetchCustomPageResponse;
    };
  }
}

declare module '@ui/settings/settings' {
  interface Settings extends BaseBackendSettings {
    homepage?: {
      type?: string;
      value?: string;
    };
    ads?: {
      disable?: boolean;
    };
    hcLanding: HcLandingPageConfig;
    hc?: {
      newTicket?: {
        appearance?: CustomerNewTicketPageConfig;
      };
    };
    articles?: {
      default_order?: string;
    };
    article?: {
      hide_new_ticket_link?: boolean;
    };
    replies?: {
      after_reply_action?: AfterReplyAction;
      create_from_emails?: boolean;
      send_email?: boolean;
    };
    envato?: {
      enable: boolean;
      require_purchase_code: boolean;
      active_support: boolean;
      filter_search: boolean;
    };
    mail?: {
      simplified_threading?: boolean;
      contact_page_address?: string;
    };
    tickets?: {
      log_activity?: boolean;
      create_from_emails?: boolean;
      send_ticket_created_notification?: boolean;
      send_ticket_rejected_notification?: boolean;
    };
    incoming_email?: {
      imap?: {
        connections?: ImapConnectionCredentials[];
      };
      mailgun?: {
        enabled?: boolean;
        verify?: boolean;
      };
      gmail?: {
        enabled?: boolean;
        topicName?: string;
      };
      pipe?: {
        enabled?: boolean;
      };
      api?: {
        enabled?: boolean;
      };
    };
  }
}

declare module '@ui/types/user' {
  interface User extends BaseBackendUser {
    purchase_codes?: PurchaseCode[];
    tags?: Tag[];
    secondary_emails?: {address: string}[];
    details?: {
      details?: string;
      notes?: string;
    };
  }
}

const data = getBootstrapData();
const sentryDsn = data.settings.logging.sentry_public;
if (sentryDsn && import.meta.env.PROD) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    ignoreErrors: ignoredSentryErrors,
    release: data.sentry_release,
  });
}

const app = <CommonProvider router={appRouter} />;

if (data.rendered_ssr) {
  hydrateRoot(rootEl, app);
} else {
  createRoot(rootEl).render(app);
  initSearchTermLogger();
}
