import {Navigate, RouteObject} from 'react-router-dom';
import {AuthRoute} from '@common/auth/guards/auth-route';
import {queryClient} from '@common/http/query-client';
import {agentTicketListDataQueryOptions} from '@app/agent/agent-ticket-list-page/use-agent-ticket-list-data';
import {AgentPageLayout} from '@app/agent/agent-page-layout';

const lazyRoute = async (
  cmp: keyof typeof import('@app/agent/routes/agent-routes.lazy'),
) => {
  const exports = await import('@app/agent/routes/agent-routes.lazy');
  return {
    Component: exports[cmp],
  };
};

export const agentRoutes: RouteObject[] = [
  {
    element: <AuthRoute permission="tickets.update" />,
    children: [
      {
        path: 'agent',
        element: <Navigate to="/agent/tickets" replace />,
      },
      {
        path: 'agent/tickets',
        lazy: () => lazyRoute('AgentTicketListPage'),
        loader: ({request}) => {
          const url = new URL(request.url);
          return queryClient.ensureQueryData(
            agentTicketListDataQueryOptions(url.searchParams),
          );
        },
      },
      {
        path: 'agent/tickets/new',
        lazy: () => lazyRoute('AgentNewTicketPage'),
      },
      {
        path: 'agent/tickets/:ticketId',
        lazy: () => lazyRoute('AgentTicketPage'),
      },
      {
        path: 'agent/users/:userId',
        lazy: () => lazyRoute('AgentCustomerPage'),
        children: [
          {path: '', element: <Navigate to="tickets" replace />},
          {
            path: 'tickets',
            lazy: () => lazyRoute('AgentCustomerPageTicketTable'),
          },
          {
            path: 'searches',
            lazy: () => lazyRoute('CustomerSearchesTable'),
          },
        ],
      },
      {
        path: 'agent/search',
        lazy: () => lazyRoute('AgentSearchPage'),
        children: [
          {
            index: true,
            lazy: () => lazyRoute('SearchTicketsTable'),
          },
          {
            path: 'tickets',
            lazy: () => lazyRoute('SearchTicketsTable'),
          },
          {
            path: 'users',
            lazy: () => lazyRoute('SearchUsersTable'),
          },
          {
            path: 'articles',
            lazy: () => lazyRoute('SearchArticlesTable'),
          },
        ],
      },

      // canned replies
      {
        path: 'agent/saved-replies',
        element: <AgentPageLayout />,
        children: [
          {
            index: true,
            lazy: () => lazyRoute('CannedRepliesDatatablePage'),
          },
          {
            path: 'new',
            lazy: () => lazyRoute('CreateCannedReplyPage'),
          },
          {
            path: ':replyId/update',
            lazy: () => lazyRoute('UpdateCannedReplyPage'),
          },
        ],
      },
    ],
  },
];
